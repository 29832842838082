<template>
  <div class="content">
    <el-card class="box-card">
      <div class="Untreated_top" slot="header">
        <span class="top_title">{{$t('设备类型统计')}}</span>
        <div>
          <el-button type="primary" size="small" @click="getUserList(1)">{{$t('导出EXCEL')}}</el-button>
        </div>
      </div>
      <el-row :gutter="20" class="searchBar">
        <el-col :span="6">
          <el-select v-model="search" clearable :placeholder="$t('请选择客户')">
              <el-option
                v-for="item in ocList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-col>
        <el-col :span="6">
          <el-button icon="el-icon-search" @click="getUserList"></el-button>
        </el-col>
      </el-row>
      <el-table :data="UntreatedData" height="500" style="width: 100%" border>
        <el-table-column :label="$t('设备类型ID')" prop="em_id" fixed>
        </el-table-column>
        <el-table-column :label="$t('类型名称')" prop="model_name">
        </el-table-column>
        <el-table-column :label="$t('归属客户')" prop="name">
        </el-table-column>
        <el-table-column :label="$t('总数量')" prop="total">
        </el-table-column>
      </el-table>
      <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="Page.page"
        :page-size="Page.pagesize" layout="total, prev, pager, next, jumper" :total="Page.total"
        style="text-align: center;">
      </el-pagination> -->
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'equipmentType',
  data () {
    return {
      UntreatedData: [],
      search: '',
      Page: {
        pagesize: 10,
        page: 1,
        maxpage: 1,
        total: 2
      },
      ocList: []
    }
  },
  beforeMount () {
    this.getUserList()
  },
  beforeUpdate () {
    this.ocList = this.$store.state.ocList
  },
  methods: {
    // pageSize 改变时会触发
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    // currentPage 改变时会触发
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.getUserList(val)
    },
    // 获取列表
    getUserList (report) {
      let params = {
          oc_id: this.search
        }
      if (report === 1) {
        params.is_report = report
        this.$common.postExcelFile(params, 'report/equipment_type_statistics')
        return
      }
      this.$axios.post('report/equipment_type_statistics', params).then((res) => {
        // console.log(res)
        const Data = res.data
        if (Data.code === 0) {
          this.UntreatedData = Data.data
          // this.Page = {
          //   pagesize: Data.pagesize,
          //   page: Data.data.page,
          //   maxpage: Data.data.maxpage,
          //   total: Data.data.total
          // }
        } else this.$message.error(Data.message)
      }).catch((error) => this.$message.error(error))
    }
  }
}
</script>

<style scoped>
  .Untreated_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top_title {
    color: #ff9252;
  }

  .searchBar {
    margin: 0 0 20px;
  }
  .el-range-editor.el-input__inner{
    width: 100%;
  }
  .el-col{
    margin: 10px 0;
  }
  .el-select{
    width: 100%;
  }
</style>
